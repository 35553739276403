import Resource from "./resource.js";
/* TIDECLOAK IMPLEMENTATION */
export class TideProvider extends Resource {
    getVouchers = this.makeRequest({
        method: "POST",
        path: "/tideAdminResources/new-voucher"
    });
    uploadImage = this.makeRequest({
        method: "POST",
        path: "/tide-idp-admin-resources/images/upload",
    });
    getImageName = this.makeRequest({
        method: "GET",
        path: "/tide-idp-admin-resources/images/{type}/name",
        urlParamKeys: ["type"],
        catchNotFound: true,
    });
    deleteImage = this.makeRequest({
        method: "DELETE",
        path: "/tide-idp-admin-resources/images/{type}/delete",
        urlParamKeys: ["type"],
        catchNotFound: true,
    });
    generateVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/generate-vrk",
    });
    confirmVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/confirm-vrk",
    });
    clearTempVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/clear-temp-vrk",
    });
    generateVendorId = this.makeRequest({
        method: "POST",
        path: "/vendorResources/generate-vendor-id",
    });
    signMessage = this.makeRequest({
        method: "POST",
        path: "/vendorResources/sign-message",
    });
    authorizeStripeRequest = this.makeRequest({
        method: "POST",
        path: "/vendorResources/authorize-stripe-request",
    });
    getTideJwk = this.makeRequest({
        method: "GET",
        path: "/vendorResources/get-tide-jwk",
    });
    toggleIGA = this.makeRequest({
        method: "POST",
        path: "/tideAdminResources/toggle-iga",
    });
    triggerLicenseRenewedEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerLicenseRenewedEvent/{error}"
    });
    triggerVendorKeyCreationEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerVendorKeyCreationEvent/{error}"
    });
    triggerAuthorizerUpdateEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerAuthorizerUpdateEvent/{error}"
    });
    triggerAuthorizeEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerAuthorizeEvent/{error}"
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
