import { jsxs as b, jsx as t, Fragment as J } from "react/jsx-runtime";
import { AlertGroup as vt, Alert as fe, AlertVariant as he, AlertActionCloseButton as ge, Page as bt, Modal as Qe, ModalVariant as Ct, Button as E, TextContent as It, Text as ye, TextVariants as xt, Spinner as kt, FormHelperText as Se, HelperText as Oe, HelperTextItem as Pe, Popover as At, Icon as ve, FormGroup as ee, NumberInput as be, ValidatedOptions as q, InputGroup as Re, InputGroupItem as Ce, TextInput as re, Select as ae, MenuToggle as Y, MenuToggleStatus as Ve, SelectList as le, SelectOption as Fe, TextInputGroup as Xe, TextInputGroupMain as Ze, ChipGroup as et, Chip as tt, TextInputGroupUtilities as nt, Switch as wt, TextArea as rt, Title as Tt, Card as Ie, CardHeader as St, CardTitle as xe, CardBody as ke, Grid as Ot, GridItem as me, Form as Pt, Stack as Rt, StackItem as Vt, PageSection as Ft, JumpLinks as Mt, JumpLinksItem as Et, ButtonVariant as Ue, Checkbox as Dt, Radio as Nt, MenuFooter as Lt, Dropdown as Bt, DropdownList as qt, DropdownItem as Ke, Brand as _t, Avatar as Ht } from "@patternfly/react-core";
import { createContext as Me, useContext as Ee, useState as v, useCallback as at, useEffect as se, useMemo as N, useRef as ie, forwardRef as Ut, useId as Kt, Fragment as lt, Children as st } from "react";
import { useTranslation as oe } from "react-i18next";
import $t from "keycloak-js";
import { ExclamationCircleIcon as Gt, HelpIcon as $e, EyeIcon as Wt, EyeSlashIcon as jt, TimesIcon as it, CubeIcon as zt, PaypalIcon as Jt, InstagramIcon as Yt, BitbucketIcon as Qt, MicrosoftIcon as Xt, TwitterIcon as Zt, StackOverflowIcon as en, OpenshiftIcon as tn, LinkedinIcon as nn, GoogleIcon as rn, GitlabIcon as an, FacebookSquareIcon as ln, GithubIcon as sn, MinusCircleIcon as on, PlusCircleIcon as cn, EllipsisVIcon as un } from "@patternfly/react-icons";
import { useFormContext as ce, Controller as G, useController as De, FormProvider as dn, useWatch as hn } from "react-hook-form";
import { get as z } from "lodash-es";
import "@keycloak/keycloak-admin-client";
import { PageHeader as mn, PageHeaderTools as pn, PageHeaderToolsGroup as fn, PageHeaderToolsItem as pe } from "@patternfly/react-core/deprecated";
import X from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as gn } from "@patternfly/react-styles";
import './main.css';const ot = Me(void 0), br = () => Ee(ot), yn = ({ children: e }) => {
  const [n, r] = v([]), a = (i) => {
    r((c) => c.filter((o) => o.id !== i));
  }, l = (i, c = he.success, o) => {
    r([
      {
        id: Math.random() * 100,
        message: i,
        variant: c,
        description: o
      },
      ...n
    ]);
  }, s = (i) => {
    l(i, he.danger);
  };
  return /* @__PURE__ */ b(ot.Provider, { value: { addAlert: l, addError: s }, children: [
    /* @__PURE__ */ t(vt, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: i, variant: c, message: o, description: m }) => /* @__PURE__ */ t(
      fe,
      {
        isLiveRegion: !0,
        variant: he[c],
        variantLabel: "",
        title: o,
        actionClose: /* @__PURE__ */ t(
          ge,
          {
            title: o,
            onClose: () => a(i)
          }
        ),
        timeout: !0,
        onTimeout: () => a(i),
        children: m && /* @__PURE__ */ t("p", { children: m })
      },
      i
    )) }),
    e
  ] });
}, vn = (e) => {
  const { t: n } = oe(), r = e.error, a = bn(r);
  function l() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(bt, { children: /* @__PURE__ */ t(
    Qe,
    {
      variant: Ct.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(E, { variant: "primary", onClick: l, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ b(It, { children: [
        /* @__PURE__ */ t(ye, { children: n("somethingWentWrongDescription") }),
        a && /* @__PURE__ */ t(ye, { component: xt.small, children: a })
      ] })
    }
  ) });
};
function bn(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function Cn(e, n) {
  const r = Me(n);
  return r.displayName = e, r;
}
function In(e) {
  return e != null;
}
function xn(e) {
  const n = Ee(e);
  if (In(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function kn(e, n, r) {
  const [a, l] = v(
    () => e.getItem(n) ?? r
  ), s = at((i) => {
    l(i), e.setItem(n, i);
  }, []);
  return se(() => {
    l(e.getItem(n) ?? r), window.addEventListener("storage", i);
    function i(c) {
      c.storageArea === e && (c.key === null || c.key === n) && l(c.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, n]), [a, s];
}
function An(e, n, r) {
  const a = N(
    () => JSON.stringify(r),
    [r]
  ), [l, s] = kn(
    e,
    n,
    a
  ), i = N(() => JSON.parse(l), [l]), c = at(
    (o) => s(JSON.stringify(o)),
    []
  );
  return [i, c];
}
const ct = Cn(
  "HelpContext",
  void 0
), wn = () => xn(ct), Tn = ({ children: e }) => {
  const [n, r] = An(localStorage, "helpEnabled", !0);
  function a() {
    r(!n);
  }
  return /* @__PURE__ */ t(ct.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, Sn = () => Me(void 0);
let Ae;
const Cr = () => {
  const e = Ee(Ae);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, Ir = ({
  environment: e,
  children: n
}) => {
  Ae = Sn();
  const r = ie(!1), [a, l] = v(!1), [s, i] = v(), c = N(() => {
    const o = new $t({
      url: e.serverBaseUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return o.onAuthLogout = () => o.login(), o;
  }, [e]);
  return se(() => {
    if (r.current)
      return;
    c.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => l(!0)).catch((m) => i(m)), r.current = !0;
  }, [c]), s ? /* @__PURE__ */ t(vn, { error: s }) : a ? /* @__PURE__ */ t(Ae.Provider, { value: { environment: e, keycloak: c }, children: /* @__PURE__ */ t(yn, { children: /* @__PURE__ */ t(Tn, { children: n }) }) }) : /* @__PURE__ */ t(kt, {});
};
function xr() {
  const n = document.getElementById("environment")?.textContent;
  if (typeof n != "string")
    throw new Error("Environment variables not found in the document.");
  try {
    return JSON.parse(n);
  } catch {
    throw new Error("Unable to parse environment variables as JSON.");
  }
}
const kr = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: s,
  buttonTestRole: i,
  onContinue: c,
  component: o = E,
  children: m,
  ...f
}) => {
  const [h, u] = v(!1);
  return /* @__PURE__ */ b(J, { children: [
    /* @__PURE__ */ t(
      o,
      {
        variant: s,
        onClick: () => u(!0),
        isDisabled: l,
        "data-testrole": i,
        children: a
      }
    ),
    /* @__PURE__ */ t(
      Qe,
      {
        variant: "small",
        ...f,
        title: e,
        isOpen: h,
        onClose: () => u(!1),
        actions: [
          /* @__PURE__ */ t(
            E,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                u(!1), c();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            E,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => u(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: m
      }
    )
  ] });
}, ut = ({ message: e, ...n }) => /* @__PURE__ */ t(Se, { ...n, children: /* @__PURE__ */ t(Oe, { children: /* @__PURE__ */ t(Pe, { icon: /* @__PURE__ */ t(Gt, {}), variant: "error", children: e }) }) }), te = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = wn();
  return l ? /* @__PURE__ */ t(At, { bodyContent: e, children: /* @__PURE__ */ b(J, { children: [
    !a && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (s) => s.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(ve, { isInline: r, children: /* @__PURE__ */ t($e, {}) })
      }
    ),
    a && /* @__PURE__ */ t(ve, { isInline: r, children: /* @__PURE__ */ t($e, {}) })
  ] }) }) : null;
};
function On(e) {
  if (!(typeof e > "u" || e instanceof RegExp))
    return typeof e == "object" ? e.value : e;
}
const K = ({
  name: e,
  label: n,
  labelIcon: r,
  error: a,
  children: l,
  ...s
}) => /* @__PURE__ */ b(
  ee,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(te, { helpText: r, fieldLabelId: e }) : void 0,
    ...s,
    children: [
      l,
      a && /* @__PURE__ */ t(ut, { "data-testid": `${e}-helper`, message: a.message })
    ]
  }
), Ar = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: a,
  ...l
}) => {
  const {
    control: s,
    formState: { errors: i }
  } = ce();
  return /* @__PURE__ */ t(
    K,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: a,
      children: /* @__PURE__ */ t(
        G,
        {
          ...r,
          name: e,
          control: s,
          render: ({ field: c }) => {
            const o = !!r.rules?.required, m = On(r.rules?.min), f = c.value ?? r.defaultValue, h = (u) => c.onChange(
              m !== void 0 ? Math.max(u, Number(m)) : u
            );
            return /* @__PURE__ */ t(
              be,
              {
                ...l,
                id: e,
                value: f,
                validated: i[e] ? q.error : q.default,
                required: o,
                min: Number(m),
                max: Number(r.rules?.max),
                onPlus: () => h(f + 1),
                onMinus: () => h(f - 1),
                onChange: (u) => {
                  const d = Number(u.currentTarget.value);
                  h(isNaN(d) ? r.defaultValue : d);
                }
              }
            );
          }
        }
      )
    }
  );
}, Pn = ({
  hasReveal: e = !0,
  innerRef: n,
  isTideIdp: r = !1,
  ...a
}) => {
  const { t: l } = oe(), [s, i] = v(!0);
  return /* @__PURE__ */ b(Re, { style: { display: r ? "none" : void 0 }, children: [
    /* @__PURE__ */ t(Ce, { isFill: !0, children: /* @__PURE__ */ t(
      re,
      {
        ...a,
        type: s ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      E,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => i(!s),
        children: s ? /* @__PURE__ */ t(Wt, {}) : /* @__PURE__ */ t(jt, {})
      }
    )
  ] });
}, dt = Ut(
  (e, n) => /* @__PURE__ */ t(Pn, { ...e, innerRef: n })
);
dt.displayName = "PasswordInput";
const wr = (e) => {
  const { labelIcon: n, ...r } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: s, fieldState: i } = De({
    ...e,
    defaultValue: l
  }), c = e.isTideIdp ?? !1;
  return /* @__PURE__ */ b(
    K,
    {
      style: { display: c ? "none" : void 0 },
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          dt,
          {
            isTideIdp: c,
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? q.error : q.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ t(Se, { children: /* @__PURE__ */ t(Oe, { children: /* @__PURE__ */ t(Pe, { children: e.helperText }) }) })
      ]
    }
  );
}, Rn = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: s,
  ...i
}) => {
  const {
    control: c,
    formState: { errors: o }
  } = ce(), [m, f] = v(!1);
  return /* @__PURE__ */ t(
    K,
    {
      name: n,
      label: r,
      isRequired: !!l.rules?.required,
      error: z(o, n),
      labelIcon: s,
      children: /* @__PURE__ */ t(
        G,
        {
          ...l,
          name: n,
          control: c,
          render: ({ field: { onChange: h, value: u } }) => /* @__PURE__ */ t(
            ae,
            {
              ...i,
              onClick: () => f(!m),
              onOpenChange: () => f(!1),
              selected: j(a) ? a.filter(
                (d) => Array.isArray(u) ? u.includes(d.key) : u === d.key
              ).map((d) => d.value) : u,
              toggle: (d) => /* @__PURE__ */ t(
                Y,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: d,
                  onClick: () => f(!m),
                  isExpanded: m,
                  isFullWidth: !0,
                  status: z(o, n) ? Ve.danger : void 0,
                  "aria-label": "toggle",
                  children: j(a) ? a.find(
                    (A) => A.key === (Array.isArray(u) ? u[0] : u)
                  )?.value : u
                }
              ),
              onSelect: (d, A) => {
                const w = A?.toString();
                h(Array.isArray(u) ? [w] : w), f(!1);
              },
              isOpen: m,
              children: /* @__PURE__ */ t(le, { children: a.map((d) => /* @__PURE__ */ t(Fe, { value: H(d), children: Ne(d) ? d : d.value }, H(d))) })
            }
          )
        }
      )
    }
  );
}, Z = (e) => Ne(e) ? e : e.value, Vn = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: s,
  placeholderText: i,
  onFilter: c,
  variant: o,
  ...m
}) => {
  const {
    control: f,
    formState: { errors: h }
  } = ce(), [u, d] = v(!1), [A, w] = v(""), [g, y] = v(0), V = ie(), C = a.filter(
    (p) => Z(p).toLowerCase().startsWith(A.toLowerCase())
  ), x = N(
    () => C.map((p, k) => /* @__PURE__ */ t(
      Fe,
      {
        value: H(p),
        isFocused: g === k,
        children: Z(p)
      },
      H(p)
    )),
    [g, C]
  ), L = (p, k) => {
    const T = C[g];
    switch (d(!0), p.key) {
      case "Enter": {
        p.preventDefault(), o !== $.typeaheadMulti ? w(Z(T)) : w(""), k.onChange(
          Array.isArray(k.value) ? [...k.value, H(T)] : H(T)
        ), d(!1), y(0);
        break;
      }
      case "Tab":
      case "Escape": {
        d(!1), k.onChange(void 0);
        break;
      }
      case "Backspace": {
        o === $.typeahead && k.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        p.preventDefault();
        let S = 0;
        p.key === "ArrowUp" && (g === 0 ? S = a.length - 1 : S = g - 1), p.key === "ArrowDown" && (g === a.length - 1 ? S = 0 : S = g + 1), y(S);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    K,
    {
      name: n,
      label: r,
      isRequired: !!l.rules?.required,
      error: z(h, n),
      labelIcon: s,
      children: /* @__PURE__ */ t(
        G,
        {
          ...l,
          name: n,
          control: f,
          render: ({ field: p }) => /* @__PURE__ */ t(
            ae,
            {
              ...m,
              onClick: () => d(!u),
              onOpenChange: () => d(!1),
              selected: j(a) ? a.filter(
                (k) => Array.isArray(p.value) ? p.value.includes(k.key) : p.value === k.key
              ).map((k) => k.value) : p.value,
              toggle: (k) => /* @__PURE__ */ t(
                Y,
                {
                  ref: k,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => d(!u),
                  isExpanded: u,
                  isFullWidth: !0,
                  status: z(h, n) ? Ve.danger : void 0,
                  children: /* @__PURE__ */ b(Xe, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      Ze,
                      {
                        placeholder: i,
                        value: o === $.typeahead && p.value ? j(a) ? a.find(
                          (T) => T.key === (Array.isArray(p.value) ? p.value[0] : p.value)
                        )?.value : p.value : A,
                        onClick: () => d(!u),
                        onChange: (T, S) => {
                          w(S), c?.(S);
                        },
                        onKeyDown: (T) => L(T, p),
                        autoComplete: "off",
                        innerRef: V,
                        role: "combobox",
                        isExpanded: u,
                        "aria-controls": "select-typeahead-listbox",
                        children: o === $.typeaheadMulti && Array.isArray(p.value) && /* @__PURE__ */ t(et, { "aria-label": "Current selections", children: p.value.map(
                          (T, S) => /* @__PURE__ */ t(
                            tt,
                            {
                              onClick: (O) => {
                                O.stopPropagation(), p.onChange(
                                  p.value.filter(
                                    (P) => P !== H(T)
                                  )
                                );
                              },
                              children: j(a) ? a.find((O) => T === O.key)?.value : Z(T)
                            },
                            S
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(nt, { children: !!A && /* @__PURE__ */ t(
                      E,
                      {
                        variant: "plain",
                        onClick: () => {
                          p.onChange(void 0), w(""), V?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(it, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (k, T) => {
                k?.stopPropagation();
                const S = T?.toString();
                o === $.typeaheadMulti && Array.isArray(p.value) ? p.value.includes(S) ? p.onChange(
                  p.value.filter((O) => O !== S)
                ) : p.onChange([...p.value, S]) : (p.onChange(Array.isArray(p.value) ? [S] : S), d(!1));
              },
              isOpen: u,
              children: /* @__PURE__ */ t(le, { children: x })
            }
          )
        }
      )
    }
  );
};
var $ = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))($ || {});
const j = (e) => typeof e[0] != "string", Ne = (e) => typeof e == "string", H = (e) => Ne(e) ? e : e.key, Fn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Rn, { ...n }) : /* @__PURE__ */ t(Vn, { ...n, variant: e }), Tr = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: a,
  ...l
}) => {
  const i = r ?? (n ? "false" : !1), { control: c } = ce();
  return /* @__PURE__ */ t(
    K,
    {
      hasNoPaddingTop: !0,
      name: l.name,
      isRequired: l.rules?.required === !0,
      label: l.label,
      labelIcon: a,
      children: /* @__PURE__ */ t(
        G,
        {
          control: c,
          name: l.name,
          defaultValue: i,
          render: ({ field: { onChange: o, value: m } }) => /* @__PURE__ */ t(
            wt,
            {
              ...l,
              id: l.name,
              "data-testid": l.name,
              label: e,
              isChecked: n ? m === "true" : m,
              onChange: (f, h) => {
                const u = n ? h.toString() : h;
                l.onChange?.(f, h), o(u);
              }
            }
          )
        }
      )
    }
  );
}, Sr = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: a, fieldState: l } = De({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    K,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ t(
        rt,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? q.error : q.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, Or = (e) => {
  const { labelIcon: n, helperText: r, ...a } = e, l = !!e.rules?.required, s = e.defaultValue ?? "", { field: i, fieldState: c } = De({
    ...e,
    defaultValue: s
  });
  return /* @__PURE__ */ b(
    K,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: c.error,
      children: [
        /* @__PURE__ */ t(
          re,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e["data-testid"] || e.name,
            validated: c.error ? q.error : q.default,
            isDisabled: e.isDisabled,
            ...a,
            ...i
          }
        ),
        r && /* @__PURE__ */ t(Se, { children: /* @__PURE__ */ t(Oe, { children: /* @__PURE__ */ t(Pe, { children: r }) }) })
      ]
    }
  );
}, Mn = rt, Pr = ({ icon: e }) => {
  const n = En(e);
  return /* @__PURE__ */ t(ve, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function En(e) {
  switch (e) {
    case "github":
      return sn;
    case "facebook":
      return ln;
    case "gitlab":
      return an;
    case "google":
      return rn;
    case "linkedin":
    case "linkedin-openid-connect":
      return nn;
    case "openshift-v3":
    case "openshift-v4":
      return tn;
    case "stackoverflow":
      return en;
    case "twitter":
      return Zt;
    case "microsoft":
      return Xt;
    case "bitbucket":
      return Qt;
    case "instagram":
      return Yt;
    case "paypal":
      return Jt;
    default:
      return zt;
  }
}
const Dn = "_title_180i0_2", Nn = {
  title: Dn
}, ht = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ t(
  Tt,
  {
    headingLevel: r,
    size: a,
    className: Nn.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), Ln = ({
  title: e,
  children: n,
  scrollId: r,
  className: a
}) => {
  const l = Kt();
  return /* @__PURE__ */ b(Ie, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ t(St, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(xe, { tabIndex: 0, children: /* @__PURE__ */ t(ht, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(ke, { className: "kc-form-panel__body", children: n })
  ] });
}, Bn = (e) => {
  const { title: n, children: r, scrollId: a, ...l } = e;
  return /* @__PURE__ */ t("section", { ...l, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ b(J, { children: [
    /* @__PURE__ */ t(ht, { id: a, title: n }),
    r
  ] }) });
}, qn = "_panel_1cdve_1", _n = "_sticky_1cdve_5", Ge = {
  panel: qn,
  sticky: _n
}, Hn = "kc-main-content-page-container", We = (e) => e.replace(/\s+/g, "-"), Un = ({
  label: e,
  sections: n,
  borders: r = !1,
  submitVendorCallback: a,
  tideIdp: l = !1,
  tideIdpDetails: s,
  newLicenseRequest: i,
  saveActivationPackageCallback: c,
  clearActivationPackageCallback: o,
  rotateKeyCallback: m,
  isButtonDisabled: f = !0,
  ...h
}) => {
  const { t: u } = oe(), [d, A] = v(!1), [w, g] = v(!1), [y, V] = v(""), [C, x] = v("");
  v(!1);
  const [L, p] = v(""), [k, T] = v("");
  v(""), v(""), v(!1);
  const [S, O] = v(!0), [P, F] = v(0), [W, de] = v(0), Le = N(
    () => n.filter(({ isHidden: I }) => !I),
    [n]
  );
  se(() => {
    const I = JSON.stringify(
      {
        gVRK: s?.gVRK || "",
        vendorId: s?.vendorId || "",
        payerPublic: s?.payerPublic || ""
      },
      null,
      2
    ), R = JSON.stringify(
      {
        gVRK: i?.tempgVRK || "",
        vendorId: i?.tempVendorId || ""
      },
      null,
      2
    );
    p(I), T(R), O(!gt());
  }, [s]);
  const gt = () => i !== void 0 && i.tempgVRK !== "" && i.tempVendorId !== "", yt = ({ title: I, hasSuccess: R, hasError: M, message: He }) => /* @__PURE__ */ b(J, { children: [
    M && /* @__PURE__ */ t(
      fe,
      {
        style: { margin: "0px 10px 10px 10px" },
        variant: "danger",
        isInline: !0,
        title: I,
        actionClose: /* @__PURE__ */ t(ge, { onClose: () => A(!1) }),
        children: He
      }
    ),
    R && /* @__PURE__ */ t(
      fe,
      {
        style: { margin: "0px 10px 10px 10px" },
        variant: "success",
        isInline: !0,
        title: I,
        actionClose: /* @__PURE__ */ t(ge, { onClose: () => g(!1) }),
        children: He
      }
    )
  ] }), Be = (I, R) => {
    const M = (I || 0) - 1;
    R(M);
  }, qe = (I, R) => {
    const M = I.target.value;
    R(M === "" ? M : +M);
  }, _e = (I, R) => {
    const M = (I || 0) + 1;
    R(M);
  };
  return /* @__PURE__ */ b(Ot, { hasGutter: !0, ...h, children: [
    /* @__PURE__ */ t(me, { md: 8, sm: 12, children: Le.map(({ title: I, panel: R }) => {
      const M = We(I.toLowerCase());
      return /* @__PURE__ */ t(lt, { children: r ? /* @__PURE__ */ t(
        Ln,
        {
          scrollId: M,
          title: I,
          className: Ge.panel,
          children: R
        }
      ) : /* @__PURE__ */ t(Bn, { scrollId: M, title: I, children: R }) }, I);
    }) }),
    /* @__PURE__ */ b(me, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: [
      l === !0 && /* @__PURE__ */ t(me, { children: /* @__PURE__ */ b(Ie, { style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: [
        /* @__PURE__ */ t(xe, { component: "h1", style: { textAlign: "center", fontSize: "1.25rem" }, children: "New License Request" }),
        /* @__PURE__ */ t(yt, { title: C, hasSuccess: w, hasError: d, message: y }),
        /* @__PURE__ */ b(Ie, { style: { margin: "0px 10px 10px 10px" }, children: [
          /* @__PURE__ */ t(xe, { style: { textAlign: "center" }, children: "Fill in the form below" }),
          /* @__PURE__ */ t(ke, { children: /* @__PURE__ */ b(Pt, { isHorizontal: !0, children: [
            /* @__PURE__ */ t(
              ee,
              {
                label: u("Max Total Accounts"),
                labelIcon: /* @__PURE__ */ t(te, { helpText: "How many users will have an account", fieldLabelId: "MaxTotalAccountRequest" }),
                fieldId: "max-total-account-request",
                children: /* @__PURE__ */ t(
                  be,
                  {
                    value: P,
                    onMinus: () => Be(P, F),
                    onChange: (I) => qe(I, F),
                    onPlus: () => _e(P, F),
                    inputName: "input",
                    inputAriaLabel: "number input",
                    minusBtnAriaLabel: "minus",
                    plusBtnAriaLabel: "plus"
                  }
                )
              }
            ),
            /* @__PURE__ */ t(
              ee,
              {
                label: u("Max Concurrent Accounts"),
                labelIcon: /* @__PURE__ */ t(te, { helpText: "How many accounts can use Tide at the same time", fieldLabelId: "MaxConcurrentAccountRequest" }),
                fieldId: "max-concurrent-account-request",
                children: /* @__PURE__ */ t(
                  be,
                  {
                    value: W,
                    onMinus: () => Be(W, de),
                    onChange: (I) => qe(I, de),
                    onPlus: () => _e(W, de),
                    inputName: "input",
                    inputAriaLabel: "number input",
                    minusBtnAriaLabel: "minus",
                    plusBtnAriaLabel: "plus"
                  }
                )
              }
            )
          ] }) })
        ] }),
        /* @__PURE__ */ t(ke, { children: /* @__PURE__ */ t(Rt, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(Vt, { children: /* @__PURE__ */ t(E, { onClick: () => console.log("MOCK STRIPE BUTTON"), children: "Checkout" }) }) }) })
      ] }) }),
      l !== !0 && /* @__PURE__ */ t(Ft, { className: Ge.sticky, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ t(
        Mt,
        {
          isVertical: !0,
          scrollableSelector: `#${Hn}`,
          label: e,
          offset: 100,
          children: Le.map(({ title: I }) => {
            const R = We(I.toLowerCase());
            return (
              // note that JumpLinks currently does not work with spaces in the href
              /* @__PURE__ */ t(
                Et,
                {
                  href: `#${R}`,
                  "data-testid": `jump-link-${R}`,
                  children: I
                },
                I
              )
            );
          })
        }
      ) })
    ] })
  ] });
}, Kn = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, Rr = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: a = !1,
  children: l,
  ...s
}) => /* @__PURE__ */ t(
  E,
  {
    variant: "primary",
    isDisabled: e && !Kn(e, a, r) || n,
    ...s,
    type: "submit",
    children: l
  }
), $n = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, Gn = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: a
}) => {
  const l = N(
    () => r.map((s) => ({
      key: s,
      value: e(`locale_${s}`, $n(s) ?? s)
    })).sort((s, i) => s.value.localeCompare(i.value, a)),
    [r, a, e]
  );
  return l.length ? /* @__PURE__ */ t(dn, { ...n, children: /* @__PURE__ */ t(
    Fn,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l,
      variant: l.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, mt = (e) => e?.includes("${"), pt = (e) => e.substring(2, e.length - 1), U = (e, n, r, a) => {
  const l = n || r, s = mt(l) ? pt(l) : l, i = a ? `${a}.${s}` : s;
  return e(i || "");
}, we = (e, n) => U(e, n.displayName, n.name), Wn = ["username", "firstName", "lastName", "email"], ft = (e) => e && Wn.includes(e), _ = (e) => `${ft(e) ? "" : "attributes."}${e?.replaceAll(".", "🍺")}`, Vr = (e) => e.replaceAll(".", "🍺"), Fr = (e) => e.replaceAll("🍺", ".");
function Mr(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((s) => r(mt(s.toString()) ? pt(s) : s))
    );
    n(_(a.field), {
      message: r(a.errorMessage, {
        ...l,
        defaultValue: a.errorMessage || a.field
      }),
      type: "server"
    });
  });
}
function ue({
  required: e,
  validators: n
}) {
  return e || jn(n);
}
function jn(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Er(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return je(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(je);
}
function je(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const Q = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  children: l
}) => {
  const s = U(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = n, c = a?.(r), o = z(i, _(r.name));
  return /* @__PURE__ */ b(
    ee,
    {
      label: we(e, r) || "",
      fieldId: r.name,
      isRequired: ue(r),
      labelIcon: s ? /* @__PURE__ */ t(te, { helpText: s, fieldLabelId: r.name }) : void 0,
      children: [
        c ? /* @__PURE__ */ b(Re, { children: [
          l,
          c
        ] }) : l,
        o && /* @__PURE__ */ t(
          ut,
          {
            "data-testid": `${r.name}-helper`,
            message: o.message
          }
        )
      ]
    },
    r.name
  );
}, zn = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  ...l
}) => /* @__PURE__ */ t(Q, { t: e, form: n, attribute: r, renderer: a, children: /* @__PURE__ */ t(
  Jn,
  {
    t: e,
    form: n,
    "aria-label": we(e, r),
    name: _(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: we(e, r)
    }),
    ...l
  }
) }), Jn = ({
  t: e,
  name: n,
  inputType: r,
  form: a,
  addButtonLabel: l,
  isDisabled: s = !1,
  defaultValue: i,
  id: c,
  ...o
}) => {
  const { register: m, setValue: f, control: h } = a, u = hn({
    name: n,
    control: h,
    defaultValue: i || ""
  }), d = N(() => Array.isArray(u) && u.length !== 0 ? u : i || [""], [u]), A = (C) => {
    y([...d.slice(0, C), ...d.slice(C + 1)]);
  }, w = () => {
    y([...d, ""]);
  }, g = (C, x) => {
    y([...d.slice(0, C), x, ...d.slice(C + 1)]);
  }, y = (C) => {
    const x = C.flatMap((L) => L);
    f(n, x, {
      shouldDirty: !0
    });
  }, V = r.startsWith("html") ? r.substring(6) : "text";
  return se(() => {
    m(n);
  }, [m]), /* @__PURE__ */ t("div", { id: c, children: d.map((C, x) => /* @__PURE__ */ b(lt, { children: [
    /* @__PURE__ */ b(Re, { children: [
      /* @__PURE__ */ t(Ce, { isFill: !0, children: /* @__PURE__ */ t(
        re,
        {
          "data-testid": n + x,
          onChange: (L, p) => g(x, p),
          name: `${n}.${x}.value`,
          value: C,
          isDisabled: s,
          type: V,
          ...o
        }
      ) }),
      /* @__PURE__ */ t(Ce, { children: /* @__PURE__ */ t(
        E,
        {
          "data-testid": "remove" + x,
          variant: Ue.link,
          onClick: () => A(x),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: d.length === 1 || s,
          children: /* @__PURE__ */ t(on, {})
        }
      ) })
    ] }),
    x === d.length - 1 && /* @__PURE__ */ b(
      E,
      {
        variant: Ue.link,
        onClick: w,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !C || s,
        children: [
          /* @__PURE__ */ t(cn, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, x)) });
}, ze = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = ue(a), s = r.startsWith("multiselect"), i = s ? Dt : Nt, c = a.validators?.options?.options || [], o = a.annotations?.inputOptionLabels || {}, m = a.annotations?.inputOptionLabelsI18nPrefix;
  return /* @__PURE__ */ t(Q, { ...e, children: /* @__PURE__ */ t(
    G,
    {
      name: _(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: f }) => /* @__PURE__ */ t(J, { children: c.map((h) => /* @__PURE__ */ t(
        i,
        {
          id: h,
          "data-testid": h,
          label: U(e.t, o[h], h, m),
          value: h,
          isChecked: f.value.includes(h),
          onChange: () => {
            s ? f.value.includes(h) ? f.onChange(
              f.value.filter((u) => u !== h)
            ) : f.onChange([...f.value, h]) : f.onChange([h]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        h
      )) })
    }
  ) });
}, Yn = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: a,
  isOpen: l,
  menuAppendTo: s,
  direction: i,
  width: c,
  maxHeight: o,
  toggleIcon: m,
  className: f,
  children: h,
  ...u
}) => {
  const [d, A] = v(!1), w = ie(), g = () => {
    A(!d), n(!d);
  }, y = () => s === "parent" && w.current?.parentElement || "inline", V = st.toArray(
    h
  );
  return /* @__PURE__ */ t(
    ae,
    {
      ref: w,
      maxMenuHeight: ne(o),
      isScrollable: !0,
      popperProps: {
        appendTo: y(),
        direction: i,
        width: ne(c)
      },
      ...u,
      onClick: g,
      onOpenChange: (C) => A(C),
      selected: a,
      onSelect: (C, x) => {
        r?.(x || ""), g();
      },
      toggle: (C) => /* @__PURE__ */ t(
        Y,
        {
          id: e,
          ref: C,
          className: f,
          onClick: g,
          isExpanded: l,
          "aria-label": u["aria-label"],
          icon: m,
          isFullWidth: !0,
          children: V.find((x) => x.props.value === a)?.props.children || a || u["aria-label"]
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(le, { children: h })
    }
  );
}, Qn = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: a,
  variant: l,
  validated: s,
  placeholderText: i,
  maxHeight: c,
  width: o,
  toggleIcon: m,
  direction: f,
  selections: h,
  typeAheadAriaLabel: u,
  chipGroupComponent: d,
  chipGroupProps: A,
  footer: w,
  children: g,
  ...y
}) => {
  const [V, C] = v(""), [x, L] = v(0), p = ie(), k = st.toArray(
    g
  ), T = () => {
    r?.(!y.isOpen);
  }, S = (O) => {
    const P = k[x];
    switch (r?.(!0), O.key) {
      case "Enter": {
        O.preventDefault(), l !== B.typeaheadMulti ? C(P.props.value) : C(""), n?.(P.props.value), r?.(!1), L(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        l === B.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        O.preventDefault();
        let F = 0;
        O.key === "ArrowUp" && (x === 0 ? F = k.length - 1 : F = x - 1), O.key === "ArrowDown" && (x === k.length - 1 ? F = 0 : F = x + 1), L(F);
        break;
      }
    }
  };
  return /* @__PURE__ */ b(
    ae,
    {
      ...y,
      onClick: T,
      onOpenChange: (O) => r?.(O),
      onSelect: (O, P) => n?.(P || ""),
      maxMenuHeight: ne(c),
      popperProps: { direction: f, width: ne(o) },
      toggle: (O) => /* @__PURE__ */ t(
        Y,
        {
          ref: O,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: m,
          isExpanded: y.isOpen,
          isFullWidth: !0,
          status: s === "error" ? Ve.danger : void 0,
          children: /* @__PURE__ */ b(Xe, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              Ze,
              {
                placeholder: i,
                value: l === B.typeahead && h ? h : V,
                onClick: T,
                onChange: (P, F) => {
                  C(F), a?.(F);
                },
                onKeyDown: (P) => S(P),
                autoComplete: "off",
                innerRef: p,
                role: "combobox",
                isExpanded: y.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": u,
                children: l === B.typeaheadMulti && Array.isArray(h) && (d || /* @__PURE__ */ t(et, { ...A, children: h.map((P, F) => /* @__PURE__ */ t(
                  tt,
                  {
                    onClick: (W) => {
                      W.stopPropagation(), n?.(P);
                    },
                    children: P
                  },
                  F
                )) }))
              }
            ),
            /* @__PURE__ */ t(nt, { children: !!V && /* @__PURE__ */ t(
              E,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), C(""), a?.(""), p?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(it, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(le, { children: g }),
        w && /* @__PURE__ */ t(Lt, { children: w })
      ]
    }
  );
};
var B = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(B || {});
const ne = (e) => typeof e == "number" ? e + "px" : e, Xn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Yn, { ...n }) : /* @__PURE__ */ t(Qn, { ...n, variant: e }), Je = (e) => {
  const { t: n, form: r, inputType: a, attribute: l } = e, [s, i] = v(!1), [c, o] = v(""), m = a === "multiselect", f = (g, y) => {
    m ? y.value.includes(g) ? y.onChange(y.value.filter((V) => V !== g)) : Array.isArray(y.value) ? y.onChange([...y.value, g]) : y.onChange([g]) : y.onChange(g === y.value ? "" : g);
  }, h = l.validators?.options?.options || [], u = l.annotations?.inputOptionLabels || {}, d = l.annotations?.inputOptionLabelsI18nPrefix, A = (g) => U(e.t, u[g], g, d), w = (g) => h.filter(
    (y) => A(y).toLowerCase().includes(c.toLowerCase())
  ).map((y) => /* @__PURE__ */ t(
    Fe,
    {
      selected: g === y,
      value: y,
      children: A(y)
    },
    y
  ));
  return /* @__PURE__ */ t(Q, { ...e, children: /* @__PURE__ */ t(
    G,
    {
      name: _(l.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: g }) => /* @__PURE__ */ t(
        Xn,
        {
          toggleId: l.name,
          onToggle: (y) => i(y),
          onClear: () => f("", g),
          onSelect: (y) => {
            const V = y.toString();
            f(V, g), Array.isArray(g.value) || i(!1);
          },
          selections: m && Array.isArray(g.value) ? g.value : A(g.value),
          variant: m ? B.typeaheadMulti : h.length >= 10 ? B.typeahead : B.single,
          "aria-label": n("selectOne"),
          isOpen: s,
          isDisabled: l.readOnly,
          onFilter: (y) => (o(y), w(g.value)),
          children: w(g.value)
        }
      )
    }
  ) });
}, Zn = (e) => {
  const { form: n, attribute: r } = e, a = ue(r);
  return /* @__PURE__ */ t(Q, { ...e, children: /* @__PURE__ */ t(
    Mn,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(_(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: a
    }
  ) });
}, D = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = ue(a), s = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(Q, { ...e, children: /* @__PURE__ */ t(
    re,
    {
      id: a.name,
      "data-testid": a.name,
      type: s,
      placeholder: U(
        e.t,
        a.annotations?.inputTypePlaceholder,
        a.name,
        a.annotations?.inputOptionLabelsI18nPrefix
      ),
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(_(a.name))
    }
  ) });
}, Te = {
  text: D,
  textarea: Zn,
  select: Je,
  "select-radiobuttons": ze,
  multiselect: Je,
  "multiselect-checkboxes": ze,
  "html5-email": D,
  "html5-tel": D,
  "html5-url": D,
  "html5-number": D,
  "html5-range": D,
  "html5-datetime-local": D,
  "html5-date": D,
  "html5-month": D,
  "html5-time": D,
  "multi-input": zn
}, Dr = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: a,
  currentLocale: l,
  hideReadOnly: s = !1,
  renderer: i
}) => {
  const c = N(() => {
    if (!r.attributes)
      return [];
    const o = s ? r.attributes.filter(({ readOnly: m }) => !m) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((m) => ({
      group: m,
      attributes: o.filter(
        (f) => f.group === m.name
      )
    }));
  }, [
    s,
    r.groups,
    r.attributes
  ]);
  return c.length === 0 ? null : /* @__PURE__ */ t(
    Un,
    {
      label: e("jumpToSection"),
      sections: c.filter((o) => o.attributes.length > 0).map(({ group: o, attributes: m }) => ({
        title: U(e, o.displayHeader, o.name) || e("general"),
        panel: /* @__PURE__ */ b("div", { className: "pf-v5-c-form", children: [
          o.displayDescription && /* @__PURE__ */ t(ye, { className: "pf-v5-u-pb-lg", children: U(e, o.displayDescription, "") }),
          m.map((f) => /* @__PURE__ */ t(
            er,
            {
              t: e,
              form: n,
              supportedLocales: a,
              currentLocale: l,
              renderer: i,
              attribute: f
            },
            f.name
          ))
        ] })
      }))
    }
  );
}, er = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: a,
  currentLocale: l,
  attribute: s
}) => {
  const i = n.watch(
    _(s.name)
  ), c = N(() => nr(s), [s]), o = s.multivalued || ar(i) && s.annotations?.inputType === void 0 ? Te["multi-input"] : Te[c];
  return s.name === "locale" ? /* @__PURE__ */ t(
    Gn,
    {
      form: n,
      supportedLocales: a,
      currentLocale: l,
      t: e,
      attribute: s
    }
  ) : /* @__PURE__ */ t(
    o,
    {
      t: e,
      form: n,
      inputType: c,
      attribute: s,
      renderer: r
    }
  );
}, tr = "text";
function nr(e) {
  if (ft(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return rr(n) ? n : tr;
}
const rr = (e) => typeof e == "string" && e in Te, ar = (e) => Array.isArray(e) && e.length > 1, lr = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ b(
  "svg",
  {
    className: gn(
      X.avatar,
      X.modifiers[r],
      n === "light" && X.modifiers.light,
      n === "dark" && X.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ b("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), Ye = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...a
}) => {
  const [l, s] = v(!1);
  return /* @__PURE__ */ t(
    Bt,
    {
      ...a,
      popperProps: {
        position: "right"
      },
      onOpenChange: (i) => s(i),
      toggle: (i) => /* @__PURE__ */ t(
        Y,
        {
          "data-testid": `${a["data-testid"]}-toggle`,
          ref: i,
          onClick: () => s(!l),
          isExpanded: l,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(un, {}) : n
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(qt, { children: r })
    }
  );
};
function sr(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, a = e.family_name, l = e.preferred_username;
  return r && a ? n("fullName", { givenName: r, familyName: a }) : r || a || l || n("unknownUser");
}
const Nr = ({
  keycloak: e,
  brand: { href: n, ...r },
  avatar: a,
  features: {
    hasLogout: l = !0,
    hasManageAccount: s = !0,
    hasUsername: i = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: o = [],
  toolbarItems: m,
  ...f
}) => {
  const { t: h } = oe(), u = [];
  s && u.push(
    /* @__PURE__ */ t(
      Ke,
      {
        onClick: () => e.accountManagement(),
        children: h("manageAccount")
      },
      "manageAccount"
    )
  ), l && u.push(
    /* @__PURE__ */ t(Ke, { onClick: () => e.logout(), children: h("signOut") }, "signOut")
  );
  const d = e.idTokenParsed?.picture;
  return /* @__PURE__ */ t(
    mn,
    {
      ...f,
      logo: /* @__PURE__ */ t(_t, { ...r }),
      logoProps: { href: n },
      headerTools: /* @__PURE__ */ b(pn, { children: [
        /* @__PURE__ */ b(fn, { children: [
          /* @__PURE__ */ t(
            pe,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ t(
                Ye,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...c || o,
                    u
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ t(pe, { children: m }),
          /* @__PURE__ */ t(
            pe,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ t(
                Ye,
                {
                  "data-testid": "options",
                  dropDownItems: [...o, u],
                  title: i ? sr(e.idTokenParsed, h) : void 0
                }
              )
            }
          )
        ] }),
        d || a?.src ? /* @__PURE__ */ t(Ht, { src: d, alt: h("avatar"), ...a }) : /* @__PURE__ */ t(lr, { ...a })
      ] })
    }
  );
};
export {
  yn as AlertProvider,
  kr as ContinueCancelModal,
  vn as ErrorPage,
  ut as FormErrorText,
  Ln as FormPanel,
  Rr as FormSubmitButton,
  Tn as Help,
  te as HelpItem,
  Pr as IconMapper,
  Nr as KeycloakMasthead,
  Ir as KeycloakProvider,
  Xn as KeycloakSelect,
  Mn as KeycloakTextArea,
  Ar as NumberControl,
  wr as PasswordControl,
  dt as PasswordInput,
  Un as ScrollForm,
  Bn as ScrollPanel,
  Fn as SelectControl,
  $ as SelectVariant,
  Tr as SwitchControl,
  Sr as TextAreaControl,
  Or as TextControl,
  Dr as UserProfileFields,
  Vr as beerify,
  Cn as createNamedContext,
  Fr as debeerify,
  xr as getInjectedEnvironment,
  In as isDefined,
  Er as isUserProfileError,
  U as label,
  Hn as mainPageContentId,
  Mr as setUserProfileServerError,
  br as useAlerts,
  Cr as useEnvironment,
  wn as useHelp,
  xn as useRequiredContext,
  An as useStoredState
};
