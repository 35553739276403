import Resource from "./resource.js";
export class TideUsersExt extends Resource {
    getUserDraftStatus = this.makeRequest({
        method: "GET",
        path: "/users/{id}/draft/status",
        urlParamKeys: ["id"],
    });
    getUserRoleDraftStatus = this.makeRequest({
        method: "GET",
        path: "/users/{userId}/roles/{roleId}/draft/status",
        urlParamKeys: ["userId", "roleId"],
    });
    getRoleDraftStatus = this.makeRequest({
        method: "GET",
        path: "/composite/{parentId}/child/{childId}/draft/status",
        urlParamKeys: ["parentId", "childId"],
    });
    getRequestedChangesForUsers = this.makeRequest({
        method: "GET",
        path: "/change-set/users/requests",
    });
    getRequestedChangesForRoles = this.makeRequest({
        method: "GET",
        path: "/change-set/roles/requests",
    });
    getRequestedChangesForClients = this.makeRequest({
        method: "GET",
        path: "/change-set/clients/requests",
    });
    approveDraftChangeSet = this.makeRequest({
        method: "POST",
        path: "/change-set/sign",
    });
    commitDraftChangeSet = this.makeRequest({
        method: "POST",
        path: "/change-set/commit",
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}/tide-admin",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
